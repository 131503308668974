import React from 'react'
import { Box, Grid } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from 'react-leaflet';
import { IconOptions, Icon, LatLngExpression } from "leaflet"
import 'leaflet/dist/leaflet.css';
export const Map = () => {
    const position: LatLngExpression = [-33.0425937, -71.517318]

    const customMarker = new Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon-2x.png',
        iconSize: [30, 40],
        iconAnchor: [15, 30],
    });

    return (
        <Grid item sx={{ height: { xs: 200, md: 600 }, ".leaflet-bottom": { display: "none" } }} md={6} xs={12}>
            <MapContainer center={position} zoom={13} style={{ height: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} icon={customMarker}>
                    <Popup>Limache 3405, Edificio Reitz I, oficina 58, Viña del mar, 2520000 Valparaíso, Viña del Mar</Popup>
                </Marker>
            </MapContainer>
        </Grid >
    )
}
