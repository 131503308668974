import { Box, Button, Paper, Typography } from '@mui/material'
import React from 'react'
import background from "../../assets/Hero1.png"
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                position: 'relative',
                height: 550,
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url("${background}")`,
                    height: 550,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '0',
                    filter: "blur(6px)",
                    backgroundSize: 'cover',
                    backgroundPosition:'center center'
                }}
            >
            </Box>
            <Paper
            
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 2,
                    position: 'absolute',
                    zIndex: '1000',
                    maxWidth: 400,
                    left: "50%",
                    top: "50%",
                    p: 2,
                    transform: {xs:"translate(-50%,-50%)", md:"translate(-50%,-50%)"},
                    backgroundColor:"rgba(255,255,255,0.5)"
                }}
            >
                <Typography variant='h2' fontWeight={"bold"} color={"primary"}>
                    KPT Consultores
                </Typography>

                {/* <Typography
                >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex neque, molestiae ducimus error dolor cupiditate aut sequi culpa itaque nobis exercitationem numquam! Recusandae nihil inventore ab ut ullam hic. Ut!
                </Typography> */}
                <Button variant='contained' color='primary' onClick={() => navigate("/contact")}>
                    Contactanos
                </Button>
            </Paper>
        </Box >
    )
}
