import React from 'react'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { HomeMain } from '../components/Home'
import { Hero } from '../components/Home/Hero'
import { PermaDrawerItems } from '../components/NavBar/NavBar'


export const Home = () => {
    
    return (
        <Box
            height={'auto'}
        >
            <Hero />
            <Box
                sx={{
                    // position: 'relative',
                    display: 'flex'
                }}
                px={{ xs: 0, sm: 3, md: 5, lg: 10, xl: 15 }}
                my={2}
            >
                <Box>
                    <Box
                        sx={{
                            // backgroundColor: "rgba(0,0,0,0.1)",
                            width: 300,
                            minHeight: 300,
                            maxHeight:600,
                            overflowY:'scroll',
                            borderRadius: 2,
                            position: 'sticky',
                            top: 90,
                            p: 2,
                            display: { xs: 'none', md: 'block' },
                        }}
                    >
                        <Typography variant='h6'>
                            Enlaces utiles
                        </Typography>
                        <List>
                            {PermaDrawerItems.map((item, index) => (
                                <ListItem key={item.name} disablePadding onClick={() => window.open(item.url, '_blank')} >
                                    <ListItemButton>
                                        <ListItemIcon >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>

                    </Box>
                </Box>
                <Box
                    px={{ xs: 0, md: 2 }}
                >
                    <HomeMain />
                </Box>
            </Box>
        </Box>
    )
}
