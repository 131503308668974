import React from 'react'
import { ContactUs } from '../components/ContactUs'
import { ThemeProvider } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { appTheme } from '../styles/MUI/Theme'

export const Contact = () => {
    return (
        <ThemeProvider theme={appTheme}>
            <Box
                sx={{
                    height: { xs: "100%", md: "calc(100vh - 80px)" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                <Typography variant='h2' fontWeight={"500"}>
                    Contactanos
                </Typography>
                <ContactUs />
            </Box>
        </ThemeProvider>
    )
}
