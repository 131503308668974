import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { appTheme } from '../../styles/MUI/Theme';
import { Avatar, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, ThemeProvider, Tooltip } from '@mui/material';
import "../../styles/CSS/Nav.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavRoutes } from './NavItems';
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TDrawerItemsList from './TDrawerItemsList';
import { DropDownItem } from './DropdownItem';
import SavingsIcon from '@mui/icons-material/Savings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShieldIcon from '@mui/icons-material/Shield';
import SpokeIcon from '@mui/icons-material/Spoke';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

function ElevationScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export const PermaDrawerItems = [
    {
        name: "Previred",
        icon: <SavingsIcon />,
        url: 'https://www.previred.com'
    },
    {
        name: "SII",
        icon: <MonetizationOnIcon />,
        url: 'https://homer.sii.cl/'
    },
    {
        name: "TGR",
        icon: <AccountBalanceIcon />,
        url: 'https://www.tgr.cl/'
    },
    {
        name: "DIRECCION DEL TRABAJO",
        icon: <GroupWorkIcon />,
        url: 'https://www.dt.gob.cl/portal/1626/w3-channel.html'
    }, {
        name: "ASOCIACION DE MUNICIPALIDADES",
        icon: <LocationCityIcon />,
        url: 'https://amuch.cl/'
    }, {
        name: "AFC",
        icon: <ShieldIcon />,
        url: 'https://www.afc.cl/'
    }, {
        name: "ASOCIACION DE AFP",
        icon: <SpokeIcon />,
        url: 'https://www.aafp.cl/'
    }, {
        name: "ASOCIACION ISAPRES",
        icon: <SpokeIcon />,
        url: 'http://www.isapre.cl/home'
    }, {
        name: "FONASA",
        icon: <HealthAndSafetyIcon />,
        url: 'https://www.fonasa.cl/sites/fonasa/inicio'
    }, {
        name: "IPS",
        icon: <EmojiPeopleIcon />,
        url: 'https://ips.gob.cl/inicio'
    }
    , {
        name: "CONSERVADOR DE BIENES RAICES",
        icon: <HomeIcon />,
        url: 'https://www.conservador.cl/portal/'
    }
    , {
        name: "BANCO CENTRAL",
        icon: <AccountBalanceIcon />,
        url: 'https://www.bcentral.cl/inicio'
    }, {
        name: "CMF",
        icon: <StoreIcon />,
        url: 'https://www.cmfchile.cl/portal/principal/613/w3-channel.html'
    }
]
export default function TNavBar(props: Props) {
    const theme = useTheme();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    
    React.useEffect(() => {
        setOpen(false)
    }, [location.pathname])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    return (
        <div>
            <ThemeProvider theme={appTheme}>
                <React.Fragment >
                    <CssBaseline />
                    <ElevationScroll {...props}>
                        <div>
                            {/* <Drawer
                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                        boxSizing: 'border-box',
                                    },
                                    display: { xs: "none", md: "flex" }
                                }}
                                variant="permanent"
                                anchor="left"
                            >
                                <Toolbar
                                    sx={{ height: 80 }}
                                >
                                    
                                </Toolbar>

                                <Divider />
                                <List>
                                    {PermaDrawerItems.map((item, index) => (
                                        <ListItem key={item.name} disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.name} onClick={() => window.open(item.url, '_blank')} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Drawer> */}
                            <AppBar color='primary' className='customNav'>
                                <Toolbar sx={{ justifyContent: "space-between" }}>
                                    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} >
                                        <Link to={`/`} className='logoNav' >
                                            <Box
                                                sx={{
                                                    width: 100,
                                                    "img": {
                                                        width: '100%'
                                                    }
                                                }}
                                            >
                                                <img src={"https://dev.azure.com/TesseractSoftwares/_apis/GraphProfile/MemberAvatars/5e945c95-04d1-4d71-b4ea-0b3c2b3d70e0?overrideDisplayName=KPT-Consultores&size=2"} alt="KPT Logo" />
                                            </Box>
                                        </Link>
                                    </Box>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="end"
                                        onClick={handleDrawerOpen}
                                        // sx={{ ...(open && { display: 'none' }) }}
                                        sx={{ display: { xs: "flex", md: "none" } }}
                                    >
                                        <MenuIcon color='secondary' />
                                    </IconButton>
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                        <NavRoutes />
                                        {/* <DropDownItem /> */}
                                    </Box>
                                </Toolbar>

                            </AppBar>
                            <Box
                                sx={{
                                    display: { xs: "block", md: "none" }
                                }}
                            >

                                <AppBar color='primary' className='customNav'>
                                    <Toolbar sx={{ justifyContent: "space-between" }}>
                                        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} >
                                            <Link to={`/`} className='logoNav' >
                                                <Box
                                                    sx={{
                                                        width: 200,
                                                        "img": {
                                                            width: '100%'
                                                        }
                                                    }}
                                                >
                                                    <img src={""} alt="KPT Logo" />
                                                </Box>
                                            </Link>
                                        </Box>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="end"
                                            onClick={handleDrawerOpen}
                                            // sx={{ ...(open && { display: 'none' }) }}
                                            sx={{ display: { xs: "flex", md: "none" } }}
                                        >
                                            <MenuIcon color='secondary' />
                                        </IconButton>
                                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                            <NavRoutes />
                                            {/* <DropDownItem /> */}
                                        </Box>
                                    </Toolbar>

                                </AppBar>
                            </Box>
                            <Drawer
                                PaperProps={{
                                    sx: {
                                        backgroundColor: "#ffffff",
                                        color: "#231f20",
                                        display: { xs: "block", md: "none" }
                                    }
                                }}

                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                    },
                                }}
                                variant="persistent"
                                anchor="left"
                                open={open}
                                color="primary"
                            >
                                <DrawerHeader sx={{ justifyContent: "space-between" }}>
                                    <Box sx={{ display: { xs: "flex", md: "none" }, m: 1 }} >
                                        <Link to="/" className='logoNav' >
                                            <Box
                                                sx={{
                                                    width: 100,
                                                    "img": {
                                                        width: '100%'
                                                    }
                                                }}
                                            >
                                                <img src={"https://dev.azure.com/TesseractSoftwares/_apis/GraphProfile/MemberAvatars/5e945c95-04d1-4d71-b4ea-0b3c2b3d70e0?overrideDisplayName=KPT-Consultores&size=2"} alt="KPT Logo" />
                                            </Box>
                                            {/* <Typography variant="h6" component="div" sx={{ fontFamily: "Roboto !important", fontWeight: "700" }}>
                                                TESSERACT
                                            </Typography> */}
                                        </Link>
                                    </Box>
                                    <IconButton onClick={handleDrawerClose} >
                                        {theme.direction === 'rtl' ? <ChevronLeftIcon style={{ color: "#231f20" }} /> : <ChevronRightIcon style={{ color: "#231f20" }} />}
                                    </IconButton>

                                </DrawerHeader>
                                <Divider />
                                <List component="nav">

                                    <TDrawerItemsList />
                                </List>
                                <Divider />
                                <Typography sx={{ pl: 1 }} variant='overline'>
                                    Enlaces utiles
                                </Typography>
                                <List>

                                    {PermaDrawerItems.map((item, index) => (
                                        <ListItem key={item.name} disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.name} onClick={() => window.open(item.url, '_blank')} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Drawer>
                        </div>
                    </ElevationScroll>
                    {/* <Toolbar /> */}
                    <Box sx={{ py: { xs: 9, sm: 10 } }}>
                        {props.children}
                    </Box>
                </React.Fragment>
            </ThemeProvider>
        </div>
    );
}