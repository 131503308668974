import React from 'react'
import { AppBar, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import { Routes, Route, Navigate } from 'react-router-dom'
import TNavBar from '../components/NavBar/NavBar'
import { Footer } from '../components/Footer'
import { Home } from '../page/Home'
import { Contact } from '../page/Contact'

export const AppRouter = () => {
    const drawerWidth = 240;
    return (
        <Box
            sx={{
                backgroundColor: "#fff",
                minHeight: "100vh",
                color: '#000'
            }}
        >


            <TNavBar>
                <Box
                    sx={{
                        minHeight: "calc(100vh - 80px)"
                    }}>

                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/*' element={<Navigate to={"/"} />} />

                    </Routes >
                </Box>
            </TNavBar>
            <Footer />
        </Box>
    )
}
