import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import RouteIcon from '@mui/icons-material/Route';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DiamondIcon from '@mui/icons-material/Diamond';

export const Features = () => {
    return (
        <Grid container spacing={6} my={2}>

            <Grid item lg={12} minHeight={300}

            >
                <Box
                    sx={{
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            height: 40,
                            width: 40,
                            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                            transform: "translate(-50%,-50%)",
                            left: "50%",
                            display: "flex",
                            margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <TravelExploreIcon color='info'></TravelExploreIcon>
                    </Box>
                    <Paper sx={{ height: "100%" }} elevation={6}>
                        <Typography variant='h6' color={"#000"} py={4} textAlign={"center"}>Visión</Typography>
                        <Typography variant='body1' color={"#000"} pb={4} px={4} textAlign={"justify"}>
                            Convertirse en una Empresa tecnológicamente inteligente, con procesos administrativos y operativos eficientes, que provee a todo su entorno (partes interesadas o stakeholders) y en particular a sus clientes con la prestación de servicios y a sus recursos humanos, como ventaja diferenciadora (pilar de su filosofía), bienestar en todas sus acepciones
                        </Typography>
                    </Paper>
                </Box>

            </Grid><Grid item lg={12} minHeight={300}

            >
                <Box
                    sx={{
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            height: 40,
                            width: 40,
                            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                            transform: "translate(-50%,-50%)",
                            left: "50%",
                            display: "flex",
                            margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <RouteIcon color='info'></RouteIcon>
                    </Box>
                    <Paper sx={{ height: "100%" }} elevation={6}>
                        <Typography variant='h6' color={"#000"} py={4} textAlign={"center"}>Misión</Typography>
                        <Typography variant='body1' color={"#000"} pb={4} px={4} textAlign={"justify"}>
                            “Convertirse en una Empresa tecnológicamente inteligente, con procesos administrativos y operativos eficientes, que provee a todo su entorno (partes interesadas o stakeholders) y en particular a sus clientes con la prestación de servicios y a sus recursos humanos, como ventaja diferenciadora (pilar de su filosofía), bienestar en todas sus acepciones
                        </Typography>
                    </Paper>
                </Box>

            </Grid><Grid item lg={12} minHeight={300}

            >
                <Box
                    sx={{
                        position: "relative"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            borderRadius: "50%",
                            backgroundColor: "#fff",
                            height: 40,
                            width: 40,
                            boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                            transform: "translate(-50%,-50%)",
                            left: "50%",
                            display: "flex",
                            margin: "0 auto",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <DiamondIcon color='info'></DiamondIcon>
                    </Box>
                    <Paper sx={{ height: "100%" }} elevation={6}>
                        <Typography variant='h6' color={"#000"} py={4} textAlign={"center"}>Valores</Typography>
                        <Typography variant='body1' color={"#000"} pb={4} px={4} textAlign={"justify"} sx={{ whiteSpace: "break-spaces" }}>
                            {
                                `En KPT consultores trabajamos con tres premisas básicas
                            
•	No existen preguntas que no deban realizarse, existe conocimiento que debe ser adquirido
•	Nos podemos equivocar, pero nos comprometemos a ser partícipes efectivos en la búsqueda y tareas que permitan su solución
•	Tenemos plena conciencia y fomentamos el trabajo en equipo, ya que promovemos y realzamos el talento individual, sabiendo que nunca supera el accionar coordinado y comprometido del EQUIPO KPT`
                            }
                        </Typography>
                    </Paper>
                </Box>

            </Grid>

        </Grid>
    )
}
