import { Box, Grid } from '@mui/material'
import React from 'react'
import { ServiceCard } from './Card'
import image1 from "../../../assets/Tributario.jpg"
import image2 from "../../../assets/Contable.jpg"
import image3 from "../../../assets/Consultoria.gif"


export const Services = () => {
    const services = [
        {
            title: 'CONTABLES',
            text: "",
            image: image2
        },
        {
            title: 'TRIBUTARIOS',
            text: "",
            image: image1,
        },
        {
            title: 'CONSULTORIAS FINACIERAS – CONTABLES – TRIBUTARIAS – ESTRATEGIA',
            text: "",
            image: image3,
        }
    ]
    return (
        <Box sx={{ flexGrow: 1, my: 2 }} >
            <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                {
                    services.map((card, index) => {
                        return (
                            <ServiceCard key={index} title={card.title} text={card.text} image={card.image} id={index} />
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
