// import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#f29328',
            contrastText: "#fff",
            light: "#f29328",
            dark: "rgba( 242, 147, 40 ,0.7)"
        },
        secondary: {
            main: '#313467',
            contrastText: "#f2f2f2",
            light: "#f2f2f2",
            dark: "rgba( 49, 52, 103 ,0.7)"
        },
        info: {
            main: '#f29328',
            contrastText: "#231f20",
            light: "#f2f2f2",
            dark: "#f2f2f2"
        }
    },
});