import { Box, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
// import "../styles/CSS/Home.css"


export const DropDownItem = () => {
    const actualRoute = useLocation()


    const dropDowns = [
        {
            title: "",
            paths: [
                {
                    title: "Sobre nosotros",
                    path: `/about`,
                },
                {
                    title: "Sobre nosotros",
                    path: `/successStories`,
                }
            ]
        },

    ]
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const location = useLocation()
    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {
                dropDowns.map((items, index) => {

                    return (
                        <div key={index}>
                            <div className={"navItem"} onClick={handleClick}>
                                <Typography variant="h6" component="div" sx={{ fontSize: "1rem !important" }} >
                                    {(items.title).toUpperCase()}
                                </Typography>

                            </div>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    items.paths.map((path, index) => {
                                        return (
                                            <MenuItem key={index} onClick={handleClose} sx={{ minWidth: "100px" }}>
                                                <Link to={path.path} style={{ textDecoration: "none", color: location.pathname === path.path ? "#f29328" : "#231f20" }}>
                                                    {path.title}
                                                </Link>
                                            </MenuItem>
                                        )
                                    })
                                }

                            </Menu>
                        </div>
                    )
                })
            }

        </Box>
    )
}
