import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
export const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#EAEAEA",
                height: 350,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2
            }}
        >
            <Box
                sx={{
                    width: 100
                }}
            >
                <img src={"https://dev.azure.com/TesseractSoftwares/_apis/GraphProfile/MemberAvatars/5e945c95-04d1-4d71-b4ea-0b3c2b3d70e0?overrideDisplayName=KPT-Consultores&size=2"} alt="KPT Logo" width={"100%"} />
            </Box>
            <Box>
                <Typography
                    variant='caption'
                >
                    Limache 3405, Edificio Reitz I, oficina 58, Viña del mar.
                </Typography>
            </Box>
            <Box
                sx={{
                    "a": {
                        color: "#f29328",
                        textDecoration: "none",
                        fontWeight: "400",
                        fontSize: "1.3   rem"
                    },
                    display: "flex",
                    gap: 3
                }}
            >
                <Link to={"/"}>
                    Home
                </Link>
                <Link to={"/contact"}>
                    Contactanos
                </Link>
            </Box>
            <Box px={2}>
                <Typography variant="h2" component="h3" sx={{ fontSize: "0.875rem", textAlign: "center" }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com/en-us">Tesseract</a> ®</Typography>            </Box>

        </Box >
    )
}
