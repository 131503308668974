import { Home } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink, useLocation} from "react-router-dom"
import "../../styles/CSS/TDrawerItems.css"

interface props{
    title: string;
    link: string;
    icon: JSX.Element;

}

export default function TListItem({title, link, icon}:props) {
    const location = useLocation()
    return(
        <ListItem button component={RouterLink} to={link} className={`drawerItem ${location.pathname===link ? "drawerselected" : ""}`}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={title}  className="fontDrawerItem"/>
        </ListItem>
    );
}