import React from 'react'
import { Box } from '@mui/material'
import { AboutUs } from './AboutUs'
import { Features } from './Features'
import { Services } from './Services/Services'

export const HomeMain = () => {
    return (
        <Box
            px={{ xs: 0, sm: 3, }}
            // maxHeight={"calc(100vh - 80px - 16px)"}
            width={{ md: "calc( 100% - 200px)" }}
            // overflow={'scroll'}
        >
            {/* <AboutUs /> */}
            <Features />
            <Services />
        </Box>
    )
}
