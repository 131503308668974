import React from 'react'
import { Box, Grid, Typography } from "@mui/material"

interface Props {
    image: string
    title: string
    text: string
    id: number
}
export const ServiceCard = ({ image, title, text, id }: Props) => {
    return (
        <Grid item xs={12} sm={6}   key={id} display={"flex"}>
            <Box sx={{
                position: 'relative',
                color: 'rgba(0, 0, 0, 0.87)',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                overflowWrap: 'break-word',
                backgroundColor: 'rgb(255, 255, 255)',
                backgroundClip: 'border-box',
                border: '0px solid rgba(0, 0, 0, 0.125)',
                borderRadius: '0.75rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
                overflow: 'visible',
                flexGrow: 1,
            }}>
                {/* Image */}
                <Box
                    sx={{
                        position: 'relative',
                        mx: 2,
                        mt: 2,
                        borderRadius: '0.5rem'
                    }}
                >
                    <img src={image}
                        style={{
                            width: '100%',
                            position: 'relative',
                            zIndex: 1,
                            boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem",
                            borderRadius: '0.5rem'

                        }}
                    />
                </Box>
                {/* Text */}
                <Box
                    sx={{
                        p: 3
                    }}
                >
                    <Typography variant="h5"
                        sx={{
                            margin: "8px 0px",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontSize: '1.25rem',
                            lineHeight: 1.375,
                            fontWeight: 700,
                            textDecoration: 'capitalize',
                            verticalAlign: 'unset',
                            letterSpacing: "-0.125px"

                        }}
                    >
                        {title}
                    </Typography>
                    <Typography variant="body1"
                        sx={{
                            margin: 0,
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontSize: '1rem',
                            fontWeight: 300,
                            lineHeight: 1.6,
                            color: 'rgb(123, 128, 154)'
                        }}
                    >
                        {text}
                    </Typography>
                </Box>
            </Box>
        </Grid>

    )
}
